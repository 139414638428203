import choozy from 'choozy'
import { add, each, remove } from 'martha'
import { component } from 'picoapp'
import sqzbx from 'sqzbx'

export default component((node, ctx) => {
  const { accordion, medias } = choozy(node)

  const instance = sqzbx(accordion, {
    resize: false,
    collapsible: false,
    defaultIndex: 0,
  })

  if (medias?.length) {
    each(medias, (el, i) => {
      if (i > 0) {
        add(el, 'hidden')
      }
    })
  }

  const unmount = instance.mount()

  instance.on('expand', ({ index }) => {
    if (medias?.length) {
      each(medias, (el, i) => {
        if (i === index) {
          remove(el, 'hidden')
        } else {
          add(el, 'hidden')
        }
      })
    }
  })

  ctx.on('resize', instance.resize)

  return () => {
    unmount()
  }
})
